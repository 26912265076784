import firebase from 'firebase';

// Initialize Firebase
const config = {
  apiKey: "AIzaSyDIAnSa5CBLhT3V9frjqC3V9wy6fONJ6Pc",
  authDomain: "tip-genie-staging.firebaseapp.com",
  projectId: "tip-genie-staging",
  storageBucket: "tip-genie-staging.appspot.com",
  messagingSenderId: "472669608784",
  appId: "1:472669608784:web:68bc75d8496f3b89aaaa55",
  measurementId: "G-3WTDDD0X7M"
};

firebase.initializeApp(config);
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.database();
export { auth, database, googleAuthProvider, githubAuthProvider, facebookAuthProvider, twitterAuthProvider };
